.vertical-timeline-element--work .vertical-timeline-element-icon {
box-shadow: 0 0 0 4px #2C68F4,inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05);
    background: white;
    width: 14px;
    height: 14px;
    left: 12px;
}
.vertical-timeline-element-date{
    font-family: Poppins Semibold, sans-serif !important;
    font-size: 18px !important;
    color: #131E47;
    font-weight: normal !important;
    opacity: 1 !important;
}
@media (min-width: 1170px) {
    .vertical-timeline-element--work .vertical-timeline-element-icon {
        left: calc(50% + 22px) !important;
    }
}